<template>
	<div class="main-contents display">
		<div class="tit">
			<SelectComp type="text" :value="div" cdId="PRO134" v-if="div"/><template v-else>배너관리</template>
			<template v-if="mdiv">(<SelectComp type="text" :value="mdiv" cdId="PRO135"/>)</template>
		</div>
		<div class="search-box main-display-search">
			<InputComp title="배너명" v-model="input.searchStr" @keyup.enter="getList('1')"/>
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getList('1')">
			<div class="sub_btn" @click="goDetail(false)">+ 배너등록</div>
			<div class="sub_btn dl" @click="delBanner()">삭제</div>
			<div class="sub_btn end" @click="offBanner()">노출종료</div>
		</div>
		<!-- 전체프로젝트 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="3%">
					<col width="3%">
					<col width="22%">
					<col width="12%">
					<col width="12%">
					<col width="30%">
					<col width="4%">
					<col width="4%">
					<col width="10%">
				</colgroup>
				<thead>
					<tr>
						<th><SelectComp type="yn" :value="Object.keys(checks).length != 0 && Object.keys(checks).length == list.length ? 'Y':'N'" @change="checkAll()"/></th>
						<th>순서</th>
						<th>배너명</th>
						<th>시작</th>
						<th>종료</th>
						<th>미리보기</th>
						<th>링크</th>
						<th>상세</th>
						<th>상태</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!list || list.length == 0">
						<!-- 관련내용 없을 경우 -->
						<td colspan="9" class="none">등록된 배너가 없습니다!</td>
					</tr>
					<!-- 최대 25건 조회 -->
					<tr v-else v-for="(item, idx) in list" :key="item.bannerSeq">
						<td class="score"><SelectComp type="yn" :value="checks[item.bannerSeq] ? 'Y':'N'" @change="checkItem(item)"/></td>
						<!--td class="score">{{item.displayOrd}}</td-->
						<!-- <td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageSize + (idx + 1)}}</td> -->
						<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (idx + 1)}}</td>
						<td class="score" :class="{done:item.applyState != '0'}">{{item.bannerNm}}</td>
						<td class="score" :class="{done:item.applyState != '0'}">{{item.applyStartDate | date('yyyy. MM. dd / HH:mm')}}</td>
						<td class="score" :class="{done:item.applyState != '0'}">{{item.applyEndDate | date('yyyy. MM. dd / HH:mm')}}</td>
						<td class="score banner"><ImageComp :src="'/api/main/bnr/image?bannerSeq='+item.bannerSeq" alt="배너이미지" /></td>
						<td class="score">
							<template v-if="item.bannerLinkDivCd == '02' && item.dtlLinkYn == 'Y'">◯</template>
							<template v-else>-</template>
						</td>
						<td class="score">
							<template v-if="item.bannerLinkDivCd == '01' && item.dtlImgYn == 'Y'">◯</template>
							<template v-else>-</template>
						</td>
						<td class="score">
							<div class="bn_btn" @click="goDetail(item)">수정</div>
							<div class="bn_btn" @click="delBanner(item)">삭제</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';
export default {
	components: { pagingComp },
	data() {
		return {
			div : this.$route.query.div,
			mdiv: this.$route.query.mdiv,
			input:{
				searchStr: '',
				bannerDivCd : '',
				bannerMberDivCd: '',
				pageIndex: 1,
			},
			list: [],
			checks:{},
			pageInfo : {},
		};
	},
	beforeMount(){
		switch(this.$route.name){
			case 'MAN901M01':
				this.div  = '01';
				this.mdiv = '00';
				break;
			case 'MAN901M11':
				this.div  = '02';
				this.mdiv = '00';
				break;
			case 'MAN901M21':
				this.div  = '03';
				this.mdiv = '90';
				break;
			case 'MAN901M31':
				this.div  = '03';
				this.mdiv = '91';
				break;
			case 'MAN901M41':
				this.div  = '04';
				this.mdiv = '00';
				break;	
		}
		this.input.bannerDivCd     = this.div;
		this.input.bannerMberDivCd = this.mdiv;
		this.getList();
	},
	methods : {
        getList(div, isScroll = false){

			if(div) this.input.pageIndex = 1;

            this.$.httpPost('/api/main/bnr/list', this.input)
                .then(res => {
					this.checks = {};
                    this.list = res.data.list;
                    this.pageInfo = res.data.pageInfo;
                    if(isScroll) {
						window.scroll(0, 0);
					}
                }).catch(this.$.httpErrorCommon);
        },
		goPage(page){
			this.input.pageIndex = page;
			this.getList();
		},
		goDetail(item){
			var query = {
				div : this.div,
				mdiv : this.mdiv
			}
			if(item) query.banner = item.bannerSeq;
			this.$router.push({name: this.$route.name.substr(0, 8) + '2', query});
		},
		checkItem(item){
			// console.log('checkItem start', item, Object.keys(this.checks).length, JSON.stringify(this.checks));
			var bannerSeq = item.bannerSeq;
			if(this.checks[bannerSeq]){
				delete this.checks[bannerSeq];
			} else {
				this.checks[bannerSeq] = item;
			}
			this.$forceUpdate();
			// console.log('checkItem end', item, Object.keys(this.checks).length, JSON.stringify(this.checks));
		},
		checkAll(){
			// console.log('checkAll start', Object.keys(this.checks).length, JSON.stringify(this.checks));
			var length = Object.keys(this.checks).length;
			if(length != this.list.length){
				for(var i in this.list){
					this.checks[this.list[i].bannerSeq] = this.list[i];
				}
			} else {
				this.checks = {};
			}
			this.$forceUpdate();
			// console.log('checkAll end', Object.keys(this.checks).length, JSON.stringify(this.checks));
		},
		delBanner(item){
			// console.log('delBanner start', JSON.stringify(this.checks));
			if(this.list.length == 0){
				return alert('조회 된 배너가 없습니다.');
			}
			var list = [];
			if(item){
				list.push(item);
			} else {
				if(Object.keys(this.checks).length == 0){
					return alert('선택 된 배너가 없습니다.');
				}
				for(var key in this.checks){
					list.push(this.checks[key]);
				}
			}
			this.$.popup('/adm/man/MAN901P02', {list})
				.then(res => {
					if(res) {
						// 회원가입 화면으로 이동
						this.getList();
					}
				});
			
		},
		offBanner(item){
			// console.log('offBanner start', JSON.stringify(this.checks));
			if(this.list.length == 0){
				return alert('조회 된 배너가 없습니다.');
			}

			var useYn = 'N', list = [];
			if(item){
				list.push(item);
			} else {
				if(Object.keys(this.checks).length == 0){
					return alert('선택 된 배너가 없습니다.');
				}
				var listN = [];
				for(var key in this.checks){
					var item1 = this.checks[key];
					if(item1.useYn == 'Y'){
						list.push(item1);
					} else {
						listN.push(item1);
					}
				}
				if(list.length == 0){
					useYn = 'Y';
					list = listN;
				}
			}

			this.$.popup('/adm/man/MAN901P01', {useYn, list})
				.then(res => {
					if(res) {
						// 회원가입 화면으로 이동
						this.getList();
					}
				});

		}
	}
}
</script>